<template>
    <b-row>
        <b-col cols="12" class="mb-32">
            <page-title />
        </b-col>
        <div class="magazine-container">
            <b-card>
                <div class="breadcrumb-custom">
                    <b-breadcrumb :items="breadcrumbItems"></b-breadcrumb>
                </div>
                <b-form>
                    <div class="row">
                        <div class="col-sm-12 col-md-12 col-xl-12"> <b-form-group id="input-group-2" label="ชื่อสถานะ"
                                label-for="input-2">
                                <b-form-input id="input-2" v-model="statusName" placeholder="กรอกสถานะ"
                                    required></b-form-input>
                            </b-form-group></div>
                    </div>
                    <div class="submit-container">
                        <b-button variant="outline-black-100" v-on:click=(updateArticleStatus())>บันทึก</b-button>
                    </div>
                </b-form>


            </b-card>
        </div>
    </b-row>
</template>

<script>
import { BRow, BCol, BCard, BButton, BForm, BFormGroup, BFormCheckboxGroup, BFormCheckbox, BFormSelect, BFormInput, BAlert, BSpinner, BModal, BBreadcrumb } from "bootstrap-vue";
import PageTitle from "@/layouts/components/content/page-title/PageTitle.vue";
import articleStatusService from "../../../../../services/article-status.service";

export default {
    components: {
        BRow,
        BCol,
        BCard,
        BButton,
        BForm,
        BFormGroup,
        BFormCheckboxGroup,
        BFormCheckbox,
        BFormSelect,
        BFormInput,
        BAlert,
        BSpinner,
        BModal,
        BBreadcrumb,
        PageTitle
    },
    data() {
        return {
            statusId: null,
            statusName: null,
            show: true,
            isLoading: false,
            errors: [],
            breadcrumbItems: [
                {
                    text: "สถานะบทความ",
                    href: "#",
                },
                {
                    text: "สถานะ",
                    to: { name: "article-status" },
                },
                {
                    text: "แก้ไขสถานะ",
                    active: true,
                },
            ],
        };
    },

    methods: {
        getArticleStatusById: async function (statusId) {
            this.statusId = statusId;
            let response = await articleStatusService.getArticleStatusById(statusId);
            let data = response.data.article_status;
            this.statusName = data.status_name;
        },

        updateArticleStatus: async function () {
            try {
                this.isLoading = true;
                if (this.isValidate()) {
                    let newR = {
                        id: this.statusId,
                        status_name: this.statusName,
                    }
                    await articleStatusService.updateArticleStatus(newR);
                    this.isLoading = false;
                    setTimeout(() => { this.isSaved = false; history.back();}, 3000)
                } else {
                    this.isLoading = false;
                    this.$bvModal
                        .msgBoxConfirm("กรุณาตรวจสอบข้อมูลอีกครั้ง", {
                            cancelVariant: "text",
                            okTitle: "ตกลง",
                            size: "sm",
                        })
                        .then((value) => {
                            this.boxOne = value;
                        })
                }
            } catch (error) {
                this.$bvModal
                    .msgBoxConfirm("กรุณาตรวจสอบข้อมูลอีกครั้ง", {
                        cancelVariant: "text",
                        okTitle: "ตกลง",
                        size: "sm",
                    })
                    .then((value) => {
                        this.boxOne = value;
                    })
            }


        },
        isValidate() {
            this.errors = [];
            if (!this.statusName) this.errors.push("Status Name required.");

            if (!this.errors.length) {
                return true;
            }
            return false;
        },

    },
    mounted() {
        this.getArticleStatusById(this.$route.params.id);
    }

};
</script>