export const validEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email); // Validate email format
}

export const generateUUIDV4 = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
    .replace(/[xy]/g, function (c) {
        const r = Math.random() * 16 | 0, 
            v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

export const validMobilePhone = (mobilePhone) => {
    const phoneRegex = /^[0-9]{10}$/; // Validate 10-digit numeric phone number
    return phoneRegex.test(mobilePhone);
  }